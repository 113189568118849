<template>
  <vue-scroll ref="ps" class="register-page align-vertical ps" :style="{ height: innerHeight + 'px' }">
    <div class="form-wrapper align-vertical-middle">
      <div class="form-box logout card-base card-shadow--extraLarge flex column gaps">
          <div class="box center">
             <h2 class="accent-text">Rolldxb Waiver</h2>
          </div>
          <div class="box">
            <el-form :label-position="labelPasition" :rules="rules" ref="form" label-width="160px" :model="form">
                <el-form-item label="FIRST NAME" prop="first">
                    <el-input size="medium" v-model="form.first" type="text" name="fname" id="frmNameA" autocomplete="given-name"></el-input>
                </el-form-item>
                <el-form-item label="SURNAME" prop="surname">
                    <el-input size="medium" v-model="form.surname" type="text" name="lname" id="frmNameA" autocomplete="family-name"></el-input>
                </el-form-item>
                <el-form-item label="EMAIL" prop="email">
                    <el-input size="medium" v-model.trim="form.email" type="email" name="email" id="frmEmailA" autocomplete="email"></el-input>
                </el-form-item>
                <el-form-item label="PHONE" prop="phone">
                    <el-input size="medium" v-model="form.phone" type="tel" name="phone" id="frmPhoneNumA" autocomplete="tel"></el-input>
                </el-form-item>
                <el-form-item label="BIRTHDAY" prop="birthday">
                    <el-date-picker
                      :editable="false"
                      size="medium"
                      type="date"
                      v-model="form.birthday">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="DEPENDENTS">
                  <div class="flex column lh-32">
                    <div class="pl-16 box flex justify-space-between" v-for="(c, index) in form.children" :key="index">
                      <span>{{c.first}} {{c.surname}} {{formatDate(c.birthday)}} y.o.</span> <i class="fs-20 accent-text clickable mdi mdi-close-circle-outline" @click="removeAppended(index)"></i>
                    </div>
                    <div>
                      <el-button type="text" @click="dialogAppendVisible = true"><i class="mdi mdi-account-plus-outline"></i> Add</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="confirm">
                    <el-checkbox v-model="form.confirm" v-show="false"></el-checkbox>
                    <div class="flex clickable" @click="toTerms">
                      <i :class="[form.confirm ? 'mdi mdi-checkbox-marked-outline accent-text' : 'mdi mdi-checkbox-blank-outline', 'fs-24 box center pr-10']"></i>
                      <span :class="[form.confirm ? 'accent-text' : '', 'box left center lh-20']">I agree with RollDXB safety policy and liability waiver agreement *</span>
                    </div>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size="medium" @click="submit('form')" class="signin-btn">
                    SUBMIT
                  </el-button>
                </el-form-item>
            </el-form>
          </div>
          <div class="text-center">
            <span class="pr-5">Already have an account?</span> <el-button type="text" @click="$onCommandParams('login')">Login</el-button>
          </div>
          <div class="text-center">
            <el-button type="text" @click="$onCommandParams('forgot')">Forgot Password?</el-button>
          </div>
      </div>
    </div>
    <el-dialog title="Dependent" :visible.sync="dialogAppendVisible">
      <el-form :model="formChild" :label-position="labelPasition" label-width="160px" :rules="rulesChild" ref="formChild">
            <el-form-item label="NAME" prop="first">
                <el-input size="medium" v-model="formChild.first" type="text" name="name" id="frmNameA" required autocomplete="name"></el-input>
            </el-form-item>
            <el-form-item label="BIRTHDAY" prop="birthday">
                <el-date-picker
                  :editable="false"
                  size="medium"
                  width="100%"
                  v-model="formChild.birthday">
                </el-date-picker>
            </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAppendVisible = false">Cancel</el-button>
        <el-button type="primary" @click="confirmAppend('formChild')">Add dependent</el-button>
      </span>
    </el-dialog>

    <app-dialog-terms
      @close="dialogTermsVisible = false"
      @onClickRefusalTerms="onClickRefusalTermsA"
      @onClickConfirmTerms="onClickConfirmTermsA"
      :dialogTermsVisible="dialogTermsVisible"></app-dialog-terms>
  </vue-scroll>
</template>

<script>
import { mapActions } from 'vuex'

import AppDialogTerms from '../components/DialogTerms'

export default {
  name: 'Register',

  components: {
    AppDialogTerms
  },

  data () {
    var validateConfirm = (rule, value, callback) => {
      if (value === false) {
        callback(new Error('Please read and accept terms'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        surname: [
          { required: true, message: 'Please input surname', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: 'Please input phone number', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: 'Please pick a date', trigger: 'change' }
        ],
        confirm: [
          { required: true, message: 'Please read and accept terms', trigger: 'blur' },
          { validator: validateConfirm, trigger: 'blur' }
        ]
      },
      rulesChild: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: 'Please pick a date', trigger: 'change' }
        ]
      },
      form: {
        first: '',
        surname: '',
        email: '',
        phone: '',
        birthday: '',
        children: [],
        confirm: false
      },
      formChild: {
        first: '',
        surname: '',
        birthday: ''
      },
      dialogAppendVisible: false,
      dialogTermsVisible: false,
      innerWidth: 0,
      innerHeight: 0
    }
  },

  computed: {
    labelPasition () {
      return this.innerWidth >= 768 ? 'left' : 'top'
    }
  },

  methods: {
    ...mapActions(['signup']),
    formatDate (date) {
      let birthday = +new Date(date)
      return ~~((Date.now() - birthday) / (31557600000))
    },
    confirmAppend (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.children.splice(this.form.children.length, 0, this.formChild)
          this.formChild = {
            first: '',
            surname: '',
            birthday: ''
          }
          this.dialogAppendVisible = false
        }
      })
    },
    removeAppended (index) {
      this.form.children.splice(index, 1)
    },
    toTerms () {
      this.dialogTermsVisible = true
      // this.form.confirm = true
      // this.$onCommandParams('terms')
    },
    onClickRefusalTermsA () {
      this.dialogTermsVisible = false
      this.form.confirm = false
    },
    onClickConfirmTermsA () {
      this.dialogTermsVisible = false
      this.form.confirm = true
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let model = Object.assign(
            this.form,
            {
              phone: this.form.phone.replaceAll(' ', ''),
              birthday: new Date(this.form.birthday).toISOString().slice(0, 10),
              children: this.form.children.map(c => {
                return {
                  first: c.first,
                  isActive: c.isActive,
                  birthday: new Date(c.birthday).toISOString().slice(0, 10)
                }
              })
            }
          )
          this.signup(model)
            .then(() => {
              this.form = {
                first: '',
                surname: '',
                email: '',
                phone: '',
                birthday: '',
                children: [],
                confirm: false
              }
            })
            .then(() => {
              this.$onCommandParams('home')
            })
            .catch(() => {
              this.$onCommandParams('login')
              this.$message({
                showClose: true,
                message: 'A user with this email already exists. Try to log in or use other email',
                type: 'error'
              })
            })
        }
      })
    },
    resizeOpenNav () {
      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight
    }
  },
  activated () {
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  },
  deactivated () {
    window.removeEventListener('resize', this.resizeOpenNav)
  }
}
</script>
